{
  "name": "player",
  "version": "7.0.0-rc.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 3000 --configuration development",
    "start:prod": "ng serve --port 3000 --configuration production",
    "build": "ng build && npm run gVersion && pnpm sentry:sourcemaps",
    "build:development": "ng build --configuration=development && npm run gVersion && pnpm sentry:sourcemaps",
    "build:production": "ng build --configuration=production && npm run gVersion && pnpm sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "gVersion": "node generateVersion.js",
    "serve": "serve -s dist/player/browser",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org admefy --project player ./dist/player/browser && sentry-cli sourcemaps upload --org admefy --project player ./dist/player/browser"
  },
  "private": true,
  "dependencies": {
    "@admefy/domain": "^9.18.0-rc",
    "@admefy/jswebrtc": "^2.0.1",
    "@angular/animations": "^19.2.1",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@angular/service-worker": "^19.2.1",
    "@ng-web-apis/common": "^4.11.1",
    "@ngrx/operators": "^19.0.1",
    "@ngrx/signals": "^19.0.1",
    "@sentry/angular": "^9.5.0",
    "@sentry/cli": "^2.42.2",
    "animate.css": "^4.1.1",
    "core-js": "^3.41.0",
    "hls.js": "^1.5.20",
    "ngxtension": "4.3.2",
    "plyr": "^3.7.8",
    "random-gradient": "^0.0.2",
    "randomcolor": "^0.6.2",
    "rxjs": "~7.8.2",
    "scheduler-polyfill": "^1.3.0",
    "semver": "^7.7.1",
    "socket.io-client": "^4.8.1",
    "tslib": "^2.8.1",
    "twemoji": "^14.0.2",
    "wavesurfer.js": "^7.9.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@types/jasmine": "~5.1.7",
    "@types/random-gradient": "^0.0.2",
    "@types/randomcolor": "^0.5.9",
    "@types/semver": "^7.5.8",
    "autoprefixer": "^10.4.21",
    "browserslist": "^4.24.4",
    "ctix": "^2.7.0",
    "jasmine-core": "~5.6.0",
    "karma": "~6.4.4",
    "@tailwindcss/postcss": "^4.0.12",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.3",
    "prettier": "^3.5.3",
    "serve": "^14.2.4",
    "tailwindcss": "^4.0.12",
    "typescript": "~5.8.2"
  },
  "packageManager": "pnpm@10.6.2"
}