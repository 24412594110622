import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, timeout } from 'rxjs/operators';
import { transformGeoJSONToMetadata } from './transformers/geoJSONToMetadata';
import { transformIpapiResponse } from './transformers/ipapiToMetadata';
import { transformIpQueryResponse } from './transformers/ipquertToMetadata';
import { transformMicrolinkToMetadata } from './transformers/microlinkToMetadata';
import { GeoJSONAPIResponse } from './types/geoJSON.type';
import { IpapiResponse } from './types/ipapi.type';
import { IPQuery } from './types/ipQuery.type';
import { Metadata } from './types/metadata.type';
import { GeoLocationMicrolink } from './types/microlink.type';

/**
 * Servicio para obtener metadatos de geolocalización desde diferentes APIs.
 */
@Injectable({ providedIn: 'root' })
export class MetadataService {
  private readonly _http = inject(HttpClient);
  private cache: Metadata | undefined = undefined;

  /**
   * Obtiene los datos de geolocalización desde una de las APIs disponibles.
   * Si los datos ya están en caché, se devuelven directamente.
   */
  public getGeoData(): Observable<Metadata> {
    if (this.cache) {
      return of(this.cache);
    }

    return this.tryGetGeoData<IPQuery>(
      'https://api.ipquery.io/?format=json',
    ).pipe(
      map(transformIpQueryResponse),
      catchError(() =>
        this.tryGetGeoData<GeoLocationMicrolink>(
          'https://geolocation.microlink.io',
        ).pipe(
          map(transformMicrolinkToMetadata),
          catchError(() =>
            this.tryGetGeoData<GeoJSONAPIResponse>(
              'https://get.geojs.io/v1/ip/geo.json',
            ).pipe(
              map(transformGeoJSONToMetadata),
              catchError(() =>
                this.tryGetGeoData<IpapiResponse>(
                  'https://ipapi.co/json/',
                ).pipe(
                  map(transformIpapiResponse),
                  catchError(() => of({} as Metadata)), // Valor por defecto si todas las APIs fallan
                ),
              ),
            ),
          ),
        ),
      ),
      tap((data) => (this.cache = data)),
    );
  }

  /**
   * Intenta obtener datos de geolocalización desde una URL específica.
   * @param url La URL de la API a consultar.
   * @returns Un observable con los datos obtenidos o un error si la solicitud falla.
   */
  private tryGetGeoData<T>(url: string): Observable<T> {
    return this._http.get<T>(url).pipe(
      timeout(5000), // Timeout de 5 segundos
      catchError(() => {
        throw new Error(`Failed to fetch data from ${url}`); // Lanza un error si la solicitud falla
      }),
    );
  }
}
